import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '@mdi/react';
import { mdiAccountSearch, mdiCurrencyEur, mdiEarth, mdiDebugStepOver, mdiLaptop } from '@mdi/js';
import { resetFilters, setFilterSelectedValue, setComputedFilters } from '../reducers/enrollments';
import { resetEnrollments, setEnrollmentsLoading, fetchFilters, fetchEnrollments } from '../reducers/enrollments';
// import { Trans } from 'react-i18next';
import Filter from '../partials/Filter';

function Sidebar() {

  const search = useSelector((state) => state.enrollments.search);
  const filtersLoaded = useSelector((state) => state.enrollments.filtersLoaded);
  const filtersDisabled = useSelector((state) => state.enrollments.filtersDisabled);
  const filters = useSelector((state) => state.enrollments.filters);
  const sorting = useSelector((state) => state.enrollments.sorting);
  // fixme
  // const computedFilters = useSelector((state) => state.enrollments.computedFilters);
  const dispatch = useDispatch();
  
  const iconSize = 0.75;

  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);
  const [basicFilters, setBasicFilters] = useState(true);
  const [scholarshipFilters, setScholarshipFilters] = useState(false);
  const [internationalFilters, setInternationalFilters] = useState(false);
  const [futureFilters, setFutureFilters] = useState(false);
  const [othersFilters, setOthersFilters] = useState(false);
  const [initialized, setInitialized] = useState(false);
  
  const [basicFiltersCounter, setBasicFiltersCounter] = useState(0);
  const [scholarshipFiltersCounter, setScholarshipFiltersCounter] = useState(0);
  const [internationalFiltersCounter, setInternationalFiltersCounter] = useState(0);
  const [futureFiltersCounter, setFutureFiltersCounter] = useState(0);
  const [othersFiltersCounter, setOthersFiltersCounter] = useState(0);

  const basicFiltersItems = [ 'years', 'sections', 'campuses', 'promos', 'admStatuses', 'pedagoStatuses' ];
  const scholarshipFiltersItems = [ 'scholarships', 'scholarshipsLevels', 'specialScholarships' ];
  const internationalFiltersItems = [ 'destinations' ];
  const futureFiltersItems = [ 'futures', 'futureDestinations', 'reenrollmentStatuses', 'graduated' ];
  const othersFiltersItems = [ 'seniorities', 'financialStatuses' ];

  function toggleMenuState(filters) {
    if (filters === 'basicFilters') setBasicFilters(!basicFilters);
    if (filters === 'scholarshipFilters') setScholarshipFilters(!scholarshipFilters);
    if (filters === 'internationalFilters') setInternationalFilters(!internationalFilters);
    if (filters === 'futureFilters') setFutureFilters(!futureFilters);
    if (filters === 'othersFilters') setOthersFilters(!othersFilters);
  }

  useEffect(() => {
    if (filtersLoaded === false) {
      dispatch(fetchFilters());
    }
  }, [filtersLoaded, fetchFilters, dispatch]);

  useEffect(() => {
    if (filtersLoaded && !filtersDisabled && filters && !initialized) {
      setInitialized(true);
      filterUpdate({ target: { name: 'years', value: getCurrentScolarYear() } });
    }
  }, [setFilterSelectedValue, getCurrentScolarYear, filterUpdate, filters, dispatch]);

  function getCurrentScolarYear() {
    const today = new Date();
    if (today.getMonth() < 8) {
      return (today.getFullYear() - 1) + '/' + today.getFullYear();
    } else {
      return today.getFullYear() + '/' + (today.getFullYear() + 1);
    }
  }

  function computeFiltersCounter(update) {
    if ((update.value !== '') && (update.value !== null) && (filters[update.name].selected !== '') && (filters[update.name].selected !== null)) return;
    let i = 1;
    if ((update.value === '') || (update.value === null)) i = -1;
    if (basicFiltersItems.includes(update.name)) setBasicFiltersCounter(basicFiltersCounter + i);
    else if (scholarshipFiltersItems.includes(update.name)) setScholarshipFiltersCounter(scholarshipFiltersCounter + i);
    else if (internationalFiltersItems.includes(update.name)) setInternationalFiltersCounter(internationalFiltersCounter + i);
    else if (futureFiltersItems.includes(update.name)) setFutureFiltersCounter(futureFiltersCounter + i);
    else if (othersFiltersItems.includes(update.name)) setOthersFiltersCounter(othersFiltersCounter + i);
  }

  function generateFilters(update) {
    const computedFilters = {
      year: (update === null) ? '' : (update?.name === 'years') ? update?.value : filters.years.selected,
      section: (update === null) ? '' : (update?.name === 'sections') ? update?.value : filters.sections.selected,
      campus: (update === null) ? '' : (update?.name === 'campuses') ? update?.value : filters.campuses.selected,
      promo: (update === null) ? '' : (update?.name === 'promos') ? update?.value : filters.promos.selected,
      admStatus: (update === null) ? '' : (update?.name === 'admStatuses') ? update?.value : filters.admStatuses.selected,
      pedagoStatus: (update === null) ? '' : (update?.name === 'pedagoStatuses') ? update?.value : filters.pedagoStatuses.selected,
      scholarship: (update === null) ? '' : (update?.name === 'scholarships') ? update?.value : filters.scholarships.selected,
      scholarshipLevel: (update === null) ? '' : (update?.name === 'scholarshipsLevels') ? update?.value : filters.scholarshipsLevels.selected,
      specialScholarship: (update === null) ? '' : (update?.name === 'specialScholarships') ? update?.value : filters.specialScholarships.selected,
      destination: (update === null) ? '' : (update?.name === 'destinations') ? update?.value : filters.destinations.selected,
      future: (update === null) ? '' : (update?.name === 'futures') ? update?.value : filters.futures.selected,
      futureDestination: (update === null) ? '' : (update?.name === 'futureDestinations') ? update?.value : filters.futureDestinations.selected,
      reenrollment: (update === null) ? '' : (update?.name === 'reenrollmentStatuses') ? update?.value : filters.reenrollmentStatuses.selected,
      graduated: (update === null) ? '' : (update?.name === 'graduated') ? update?.value : filters.graduated.selected,
      seniority: (update === null) ? '' : (update?.name === 'seniorities') ? update?.value : filters.seniorities.selected,
      financialStatus: (update === null) ? '' : (update?.name === 'financialStatuses') ? update?.value : filters.financialStatuses.selected,
    };
    dispatch(setComputedFilters(computedFilters));
    return computedFilters;
  }

  function filterUpdate(event) {
    if (filtersLoaded === false) return false;
    dispatch(setFilterSelectedValue({name: event.target.name, value: event.target.value}));
    dispatch(setEnrollmentsLoading());
    dispatch(resetEnrollments());
    computeFiltersCounter(event.target);
    dispatch(fetchEnrollments({ search, filters: generateFilters(event.target), sorting }));
  }

  function reset() {
    const defaultYear = { name: 'years', value: getCurrentScolarYear() };
    dispatch(resetFilters());
    dispatch(setEnrollmentsLoading());
    dispatch(resetEnrollments());
    setBasicFiltersCounter(0);
    setScholarshipFiltersCounter(0);
    setInternationalFiltersCounter(0);
    setFutureFiltersCounter(0);
    setOthersFiltersCounter(0);
    filterUpdate({ target: defaultYear });
    setBasicFiltersCounter(1);
    dispatch(fetchEnrollments({ search, filters: generateFilters(null), sorting }));
  }

  // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
  const body = document.querySelector('body');
  document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
    
    el.addEventListener('mouseover', function() {
      if(body.classList.contains('sidebar-icon-only')) {
        el.classList.add('hover-open');
      }
    });
    el.addEventListener('mouseout', function() {
      if(body.classList.contains('sidebar-icon-only')) {
        el.classList.remove('hover-open');
      }
    });
  });  

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo" href="/">
          <img src={require('../../assets/images/epitech_logo.png')} alt="Logo Epitech" />
        </a>
        <a className="sidebar-brand brand-logo-mini" href="/">
          <img src={require('../../assets/images/epitech_logo_mini.png')} alt="Logo Epitech miniature" />
        </a>
      </div>
      <ul className="nav">
        <li className="nav-item nav-category">
          <span className="nav-link">Filtres</span>
        </li>
        <li className="nav-item menu-items">
          <div className={ basicFilters ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('basicFilters') } data-toggle="collapse">
            <span className="menu-icon">
              <div className={ (basicFiltersCounter > 0) ? 'icon-container d-block' : 'icon-container' }>
                <div className="filters-count">{basicFiltersCounter}</div>
              </div>
              <Icon path={mdiAccountSearch} size={iconSize} />
            </span>
            <span className="menu-title">Basique</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ basicFilters } id="basic-filters">
            <ul className="nav flex-column sub-menu">
              <Filter id="yearSelect" label="Année scolaire" name="years" onChange={filterUpdate} />
              <Filter id="sectionSelect" label="Section" name="sections" onChange={filterUpdate} />
              <Filter id="campusSelect" label="Ville" name="campuses" onChange={filterUpdate} />
              <Filter id="promoSelect" label="Promotion" name="promos" onChange={filterUpdate} />
              <Filter id="admStatusSelect" label="Statut ADM" name="admStatuses" onChange={filterUpdate} />
              <Filter id="pedagoStatusSelect" label="Statut pédago" name="pedagoStatuses" onChange={filterUpdate} />
            </ul>
          </Collapse>
        </li>
        <li className="nav-item menu-items">
          <div className={ scholarshipFilters ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('scholarshipFilters') } data-toggle="collapse">
            <span className="menu-icon">
            <div className={ (scholarshipFiltersCounter > 0) ? 'icon-container d-block' : 'icon-container' }>
                <div className="filters-count">{scholarshipFiltersCounter}</div>
              </div>
              <Icon path={mdiCurrencyEur} size={iconSize} />
            </span>
            <span className="menu-title">Bourses</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ scholarshipFilters } id="scholarship-filters">
            <ul className="nav flex-column sub-menu">
              <Filter id="scholarshipSelect" label="Boursier CROUS" name="scholarships" onChange={filterUpdate} />
              <Filter id="scholarshipLevelselect" label="Echelon CROUS" name="scholarshipsLevels" onChange={filterUpdate} />
              <Filter id="specialScholarshipSelect" label="Bourses spéciales" name="specialScholarships" onChange={filterUpdate} />
            </ul>
          </Collapse>
        </li>
        <li className="nav-item menu-items">
          <div className={ internationalFilters ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('internationalFilters') } data-toggle="collapse">
            <span className="menu-icon">
            <div className={ (internationalFiltersCounter > 0) ? 'icon-container d-block' : 'icon-container' }>
                <div className="filters-count">{internationalFiltersCounter}</div>
              </div>
              <Icon path={mdiEarth} size={iconSize} />
            </span>
            <span className="menu-title">International</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ internationalFilters } id="international-filters">
            <ul className="nav flex-column sub-menu">
              <Filter id="destinationSelect" label="Destination" name="destinations" onChange={filterUpdate} />
            </ul>
          </Collapse>
        </li>
        <li className="nav-item menu-items">
          <div className={ futureFilters ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('futureFilters') } data-toggle="collapse">
            <span className="menu-icon">
            <div className={ (futureFiltersCounter > 0) ? 'icon-container d-block' : 'icon-container' }>
                <div className="filters-count">{futureFiltersCounter}</div>
              </div>
              <Icon path={mdiDebugStepOver} size={iconSize} />
            </span>
            <span className="menu-title">Devenir</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ futureFilters } id="future-filters">
            <ul className="nav flex-column sub-menu">
              <Filter id="futureSelect" label="Devenir" name="futures" onChange={filterUpdate} />
              <Filter id="futureDestinationSelect" label="Destination" name="futureDestinations" onChange={filterUpdate} />
              <Filter id="reenrollmentSelect" label="Réinscription lancée" name="reenrollmentStatuses" onChange={filterUpdate} />
              <Filter id="graduationSelect" label="Titré/Diplômé" name="graduated" onChange={filterUpdate} />
            </ul>
          </Collapse>
        </li>
        <li className="nav-item menu-items">
          <div className={ othersFilters ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('othersFilters') } data-toggle="collapse">
            <span className="menu-icon">
            <div className={ (othersFiltersCounter > 0) ? 'icon-container d-block' : 'icon-container' }>
                <div className="filters-count">{othersFiltersCounter}</div>
              </div>
              <Icon path={mdiLaptop} size={iconSize} />
            </span>
            <span className="menu-title">Autres</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={ othersFilters } id="others-filters">
            <ul className="nav flex-column sub-menu">
              <Filter id="senioritySelect" label="Ancienneté" name="seniorities" onChange={filterUpdate} />
              <Filter id="financialStatusSelect" label="Statut financier" name="financialStatuses" onChange={filterUpdate} />
            </ul>
          </Collapse>
        </li>
        <li className="nav-item menu-items nav-button">
          <div className="form-group">
            <div id="reinit-filters">
              <button className="btn btn-outline-light btn-fw" type="button" onClick={ () => reset() }> Réinitialiser </button>
              <button className="btn btn-outline-light btn-rounded btn-icon" type="button"><i className="mdi mdi-reload btn-icon-prepend"></i></button>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);