import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    dispay: false,
    type: '',
    massAction: false,
  },
  reducers: {
    openModal: (state, action = null) => {
      if (action !== null) {
        if (action.payload?.type !== undefined)
          state.type = action.payload.type;
        if (action.payload?.massAction !== undefined)
          state.massAction = action.payload.massAction;
      }
      state.display = true;
    },
    closeModal: (state) => {
      state.display = false;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setMassAction: (state, action) => {
      state.massAction = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  openModal,
  closeModal,
  setType,
  setMassAction,
  setSelectedValue,
} = modalSlice.actions

export default modalSlice.reducer